<template>
  <div>
    <div class="flex justify-between items-center">
      <tabs :tabs="tabs" :tab="tab" v-model="tab" />
      <button class="btn btn-blue btn-sm" @click="$refs.settingsModal.open()">
        + Add New Deal
      </button>
    </div>
    <div class="mt-5">
      <component :is="tab" :profile="profile" v-on="listeners" />
    </div>

    <modal className="w-11/12 md:w-443px lg:w-443px" ref="settingsModal">
      <h1 class="text-2xl text-left font-bold mb-8">
        Add {{ profile.business_name }} Deal
      </h1>
      <form @submit.prevent="updateSettings">
        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>

        <div class="mt-3">
          <form-group
            type="select"
            :options="types"
            name="type"
            :form="form"
            v-model="form.data.type.value"
          >
            Merchant Discount Type
          </form-group>
        </div>

        <form-group
          name="commission"
          :form="form"
          v-model="form.data.commission.value"
        >
          Merchant Discount
        </form-group>

        <div class="border-0.7 w-full mb-3"></div>

        <div class="mt-3">
          <form-group
            type="select"
            :options="types"
            leftIcon="business-outline"
            name="credpal_commission_type"
            :form="form"
            v-model="form.data.credpal_commission_type.value"
          >
            Credpal Discount Type
          </form-group>
        </div>

        <form-group
          name="credpal_commission"
          :form="form"
          v-model="form.data.credpal_commission.value"
        >
          Credpal Discount
        </form-group>

        <div class="border-0.7 w-full mt-1 mb-3"></div>

        <form-group
          type="money"
          name="minimum"
          :form="form"
          v-model="form.data.minimum.value"
        >
          Minimum Amount
        </form-group>

        <form-group
          type="money"
          name="maximum"
          :form="form"
          v-model="form.data.maximum.value"
        >
          Maximum Amount
        </form-group>

        <div class="border-0.7 w-full mt-1 mb-3"></div>

        <form-group
          type="money"
          name="maximum_discount_value"
          :form="form"
          v-model="form.data.maximum_discount_value.value"
        >
          Maximum Discount Amount
        </form-group>

        <div class="mb-5">
          <h5 class="text-10 mb-2 text-gray-500">Deal Image</h5>
          <dropzone
            class="mb-0 mr-2 w-full"
            accept=".png,.jpg,.jpeg"
            v-model="form.data.image.value"
          />
        </div>

        <div class="flex justify-center">
          <button
            class="btn btn-blue font-normal py-4 px-16"
            type="submit"
            :disabled="form.loading"
          >
            Submit
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>
<script>
export default {
  props: {
    profile: {
      required: true,
      type: [Array, Object]
    }
  },
  components: {
    activated: () => import('./Activated'),
    deactivated: () => import('./Deactivated')
  },
  data() {
    return {
      tab: 'activated',
      form: this.$options.basicForm([
        { name: 'type', rules: 'nullable' },
        { name: 'commission', rules: 'nullable' },
        { name: 'credpal_commission', rules: 'nullable' },
        { name: 'credpal_commission_type', rules: 'nullable' },
        { name: 'minimum', rules: 'nullable' },
        { name: 'maximum', rules: 'nullable' },
        { name: 'maximum_discount_value', rules: 'required' },
        { name: 'image', value: null, rules: 'nullable' }
      ])
    };
  },
  computed: {
    tabs() {
      return [
        { title: 'Active', name: 'activated' },
        { title: 'Deactivated', name: 'deactivated' }
      ];
    },
    types() {
      return [
        { title: 'Percentage', value: 'percentage' },
        { title: 'Flat', value: 'flat' }
      ];
    },
    sources() {
      return [
        { title: 'Merchant', value: 'merchant' },
        { title: 'Credpal', value: 'credpal' }
      ];
    },
    listeners() {
      return {
        ...this.$listeners,
        success: () => this.$emit('success'),
        deleteSetting: setting => this.deleteSetting(setting)
      };
    }
  },
  methods: {
    async updateSettings() {
      if (!this.validateForm(this.form)) return false;
      this.form.loading = true;
      this.form.error = false;

      const data = new FormData();
      data.append('merchant_id', this.profile?.id);
      data.append('type', this.form.data.type.value);
      data.append('commission', this.form.data.commission.value);
      data.append(
        'credpal_commission',
        this.form.data.credpal_commission.value
      );
      data.append(
        'credpal_commission_type',
        this.form.data.credpal_commission_type.value
      );
      data.append('minimum', this.form.data.minimum.value);
      data.append('maximum', this.form.data.maximum.value);
      data.append(
        'maximum_discount_value',
        this.form.data.maximum_discount_value.value
      );
      data.append('image', this.form.data.image.value);

      await this.sendRequest('admin.merchants.settings', {
        data: data,
        success: () => {
          this.$emit('success');
          this.clearFormData(this.form);
          this.$refs.settingsModal.close();
        },
        error: error => (this.form.error = error)
      });
      this.form.loading = false;
    }
  },
  async deleteCashbackSetting({ id }) {
    await this.sendRequest('admin.merchants.deleteSetting', id, {
      success: () => this.$emit('success'),
      error: error => console.log(error)
    });
  }
};
</script>
